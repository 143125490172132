.react-calendar {
    max-width: 100%;
    background: white;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}

.react-calendar--doubleView {
    width: 100%;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
    width: 50%;
    margin: 0.5em;
}

.react-calendar, .react-calendar *, .react-calendar *:before, .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar__navigation {
    height: 20px;
    margin-bottom: 1em;
}

.react-calendar__navigation button {
    min-width: 20px;
    height: 20px;
    border-radius: 360px;
    background: rgba(72, 68, 242, 0.12);
}

.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
    color: rgba(13, 13, 13, 0.38);
    text-decoration: none !important;
    font-size: 12px;
    font-weight: normal;
}

.react-calendar__month-view__weekdays abbr {
    text-decoration: none !important;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    text-decoration: none !important;
}

.react-calendar__month-view__weekNumbers {
    font-weight: bold;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__month-view__days__day--weekend {}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
    visibility: hidden;
}

.react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}

.react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
}

.react-calendar__tile:disabled {
    background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
    background-color: rgba(72, 68, 242, 0.03);
}

.react-calendar__tile--now {}

.react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus {}

.react-calendar__tile--hasActive {
    background: #f4f4ff;
}

.react-calendar__tile--hasActive:enabled:hover, .react-calendar__tile--hasActive:enabled:focus {
    background: rgba(72, 68, 242, 0.03);
}

.react-calendar__tile--active {
    background: #f4f4ff;
    color: rgba(13, 13, 13, 0.87);
}

.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: rgba(72, 68, 242, 0.06);
}

.react-calendar__tile--rangeStart {
    border-top-left-radius: 360px;
    border-bottom-left-radius: 360px;
    color: #1600aa;
    font-weight: bold;
}

.react-calendar__tile--rangeEnd {
    border-top-right-radius: 360px;
    border-bottom-right-radius: 360px;
    color: #1600aa;
}

.react-calendar__tile--hoverEnd {}

.react-calendar__navigation__label {
    color: #1600aa;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    pointer-events: none;
    background: transparent !important;
}

.react-calendar__navigation__label__labelText--from {
    text-align: center;
    width: 50%;
}

.react-calendar__navigation__label__labelText--to {
    text-align: center;
    width: 50%;
}

.react-calendar__navigation__prev2-button {
    display: none;
}

.react-calendar__navigation__next2-button {
    display: none;
}

.react-calendar__navigation__label__divider {
    display: none;
}

.react-calendar__navigation__prev-button {
    border-radius: 360px;
    background: rgba(72, 68, 242, 0.12);
}

.react-calendar__navigation__next-button button {
    border-radius: 360px;
    background: red;
}